<template>
    <div>
        <v-row v-if="!onlySimpleSearchDefault">
            <v-col sm="3" v-if="!hideTitleDefault">
                <h1 class="title font-weight-light">
                    {{ listName }} ({{ $format.int(pager.total) }})
                    <v-tooltip right>
                        <template v-if="!hideAddNewDefault" v-slot:activator="{ on }">
                            <v-btn icon color="primary" dark v-on="on" @click="emitOnNew"><v-icon>mdi-plus-circle</v-icon></v-btn>
                        </template>
                        <span>{{ addNew }}</span>
                    </v-tooltip>
                </h1>
            </v-col>
            <v-col sm="1" v-if="!hideGridRefreshDefault">
                <v-btn color="primary" dark small right fab :loading="isBusy" @click="emitLoadData">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-col>
            <v-col sm="2" v-if="viewStatusLabel">
                <v-menu open-on-hover bottom offset-y nudge-top="-10">
                    <template v-slot:activator="{ on }">
                        <span class="forStatus">
                            <v-chip
                                label
                                :color="viewStatus.color"
                                text-color="white"
                                v-on="on"
                                class="mt-1">
                                <v-icon left>
                                    mdi-filter-variant
                                </v-icon>
                                {{ viewStatus.text }}
                            </v-chip>
                        </span>
                    </template>
                    <v-list>
                        <v-list-item v-for="item in viewStatusOptions" :key="item.id" @click="emitViewStatusClick(item)">
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
            <v-col sm="4">
                <v-container class="pa-0" v-if="abilityToSearch">
                    <v-row no-gutters>
                        <v-spacer></v-spacer>
                        <v-col sm="auto" justify-self="end">
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click="emitToggleSearchAt" style="display: none;">
                                        <v-icon>{{ searchAt.icon }}</v-icon>
                                    </v-btn>
                                </template>
                                <span>Match search text at this position</span>
                            </v-tooltip>
                        </v-col>
                        <v-col sm="10">
                            <v-text-field
                                ref="search"
                                v-model="localSearchText"
                                dense
                                placeholder="Type here to filter the list"
                                :hint="`(${searchAt.text}) ${searchOnFields.join(', ')}`"
                                prepend-icon="mdi-magnify"
                                persistent-hint
                                clearable
                                autofocus
                                @keyup.native="emitSearchChange"
                                @click:clear="emitClearSearch">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
            <v-col sm="2" style="text-align: center;" class="mt-2">
                <div v-if="deletingPermission">
                    <v-menu v-if="selected.length > 0" bottom left offset-y>
                        <template v-slot:activator="{ on }">
                            <v-icon class="ml-3" large v-on="on" color="error" :loading="isDeleting">mdi mdi-delete</v-icon>
                        </template>
                        <v-btn color="error" @click="emitOnDeleteAll()">
                            <v-icon class="mr-2">mdi-alert-decagram</v-icon>
                            Are you sure?
                        </v-btn>
                    </v-menu>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="onlySimpleSearchDefault">
            <v-col sm="12">
                <v-container class="pa-0" v-if="abilityToSearch">
                    <v-row no-gutters>
                        <v-col sm="10">
                            <v-text-field
                                ref="search"
                                v-model="localSearchText"
                                dense
                                placeholder="Type here to filter the list"
                                :hint="`(${searchAt.text}) ${searchOnFields.join(', ')}`"
                                persistent-hint
                                clearable
                                autofocus
                                @keyup.native="emitSearchChange"
                                @click:clear="emitClearSearch">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col sm="12">
                <v-card>
                    <span>
                        <slot></slot>
                    </span>
                    <v-divider></v-divider>
                    <v-row class="mt-0">
                        <v-col sm="10" class="pl-5 d-flex justify-start">
                            <v-pagination
                                :key="reloadKey"
                                v-model="pager.page"
                                :length="pager.pages"
                                :total-visible="(pager.pages > 7 ? 7 : pager.pages)"
                                @next="emitNext"
                                @previous="emitPrevious"
                                @input="emitInput">
                            </v-pagination>
                        </v-col>
                        <v-col sm="2" class="pt-5 pr-7 d-flex justify-end">
                            <v-select
                                v-model="pager.size"
                                :items="pageSizes"
                                label="Page Size"
                                dense hide-details
                                style="max-width:80px;"
                                @change="emitPageSize">
                            </v-select>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    name: 'v-dataTable',
    props: [
        'pager',
        'pageSizes',
        'listName',
        'addNew',
        'isBusy',
        'isDeleting',
        'selected',
        'viewStatusOptions',
        'viewStatus',
        'searchAt',
        'searchOnFields',
        'searchText',
        'deletingPermission',
        'viewStatusLabel',
        'abilityToSearch',
        'searchAtOptions',
        'hideTitle',
        'hideAddNew',
        'hideGridRefresh',
        'onlySimpleSearch'
    ],
    data () {
        return {
            localSearchText: this.searchText,
            selectedStatus: {},
            reloadKey: 0,
            searchTimer: null
        };
    },
    mounted () {},
    computed: {
        hideTitleComputed () {
            return typeof this.hideTitle !== 'undefined' ? this.hideTitle : false;
        },
        hideAddNewComputed () {
            return typeof this.hideAddNew !== 'undefined' ? this.hideAddNew : false;
        },
        hideGridRefreshComputed () {
            return typeof this.hideGridRefresh !== 'undefined' ? this.hideGridRefresh : false;
        },
        onlySimpleSearchComputed () {
            return typeof this.onlySimpleSearch !== 'undefined' ? this.onlySimpleSearch : false;
        },
        hideTitleDefault () {
            return typeof this.hideTitle !== 'undefined' ? this.hideTitle : false;
        },
        hideAddNewDefault () {
            return typeof this.hideAddNew !== 'undefined' ? this.hideAddNew : false;
        },
        hideGridRefreshDefault () {
            return typeof this.hideGridRefresh !== 'undefined' ? this.hideGridRefresh : false;
        },
        onlySimpleSearchDefault () {
            return typeof this.onlySimpleSearch !== 'undefined' ? this.onlySimpleSearch : false;
        }
    },
    created () {},
    methods: {
        emitNext () {
            this.$emit('next-page', this.pager.page);
        },
        emitPrevious () {
            this.$emit('previous-page', this.pager.page);
        },
        emitInput () {
            this.calcNewPagingSize();
            this.$emit('input-page', this.pager.page);
        },
        emitPageSize () {
            this.calcNewPagingSize();
            this.$emit('page-size-change', this.pager.size);
        },
        emitOnNew () {
            this.$emit('on-new', this.listName);
        },
        emitLoadData () {
            this.$emit('page-load-data', this.loadData);
        },
        emitOnDeleteAll () {
            if (this.deletingPermission) {
                this.$emit('delete-all', this.deleteAll);
            }
        },
        emitViewStatusClick (item) {
            this.selectedStatus = item;
            this.$emit('view-status', this.selectedStatus, this.viewStatus);
        },
        emitToggleSearchAt () {
            this.$emit('toggle-search', this.toggleSearchAt);
        },
        emitSearchChange () {
            this.debounceSearch();
            // this.$emit('search-change', this.localSearchText, this.searchChange);
        },
        emitClearSearch () {
            this.localSearchText = '';
            this.calcNewPagingSize();
            this.$emit('search-change', this.localSearchText, this.searchChange);
        },
        calcNewPagingSize () {
            const calcPageSize = this.pager.total / this.pager.size;
            const numberString = calcPageSize.toString();
            const hasZeroValue = /\.0/.test(numberString);
            if (hasZeroValue) {
                this.pager.pages = 1;
            }
            else {
                const newPageSize = Math.ceil(calcPageSize);
                this.pager.pages = newPageSize;
            };
            this.hardReloadComponent();
        },
        hardReloadComponent () {
            this.reloadKey += 1;
        },
        debounceSearch () {
            // cancel pending call
            clearTimeout(this.searchTimer);

            // delay new call 500ms
            this.searchTimer = setTimeout(() => {
                this.$emit('search-change', this.localSearchText, this.searchChange);
            }, 500);
        }
    },
    watch: {
        pager: {
            deep: true,
            handler (newData) {
                this.calcNewPagingSize();
            }
        }
    }
};
</script>

<style scoped>
.forStatus::before {
    content: "Status";
    color: rgba(0,0,0,.54);
    font-size: 9pt;
    position: absolute;
    margin-top: 35px;
}
</style>
