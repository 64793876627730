<template>
    <v-text-field
        v-model="model"
        mask="###-###-####"
        type="number"
        @change="onChange"
        v-bind="$attrs">
    </v-text-field>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
    name: 'v-mask',
    directives: { mask },
    props: {
        value: null,
        mask: { type: String, default: '###-###-####' }
    },
    data () {
        return {
            model: this.value
        };
    },
    methods: {
        onChange () {
            this.$emit('input', this.model);
            if (this.$listeners.change) this.$listeners.change();
        }
    }
};
</script>

<style  lang="scss" scoped>
</style>
