<template>
    <div @click="handleClick">
        <div v-for="(img, i) in imgUris" :key="`${imgField}_${i}`">
            <img :src="img" :width="imgWidth" style="max-width:500px;"/>
        </div>
    </div>
</template>

<script>
import Constants from '@/util/Constants';

export default {
    name: 'LazyImgArrVertical',
    props: {
        inputField: {
            type: String,
            default: ''
        },
        inputUrls: {
            type: [Array, String],
            default: null
        },
        inputWidth: {
            type: Number,
            default: 200
        },
        emitClick: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            imgField: this.inputField,
            imgUris: [],
            imgWidth: this.inputWidth || 200
        };
    },
    mounted () {
        this.fetchImages();
    },
    methods: {
        fetchImages () {
            this.imgUris = [];
            if (!this.inputUrls || this.inputUrls === '') {
                this.imgUris.push(Constants.NO_IMAGE);
                return;
            }

            if (Array.isArray(this.inputUrls)) {
                for (let i = 0; i < this.inputUrls.length; i++) {
                    this.fetchSingleImage(this.inputUrls[i]);
                }
            }
            else {
                this.fetchSingleImage(this.inputUrls);
            }
        },
        fetchSingleImage (url) {
            this.$http.request({ url: url, method: 'GET', responseType: 'blob' })
                .then(res => {
                    if (res && res.data && `${res.data.type}`.includes('image/')) {
                        this.imgUris.push(URL.createObjectURL(res.data));
                    }
                    else {
                        this.imgUris.push(Constants.NO_IMAGE);
                    }
                })
                .catch(() => {
                    this.imgUris.push(Constants.NO_IMAGE);
                });
        },
        handleClick () {
            if (this.emitClick) this.$emit('click');
        }
    },
    watch: {
        inputUrls: {
            handler () {
                this.fetchImages();
            }
        }
    }
};
</script>
