<template>
    <div v-bind="$attrs">
        <span class="caption mr-2" style="margin-top:2px;">Filters</span>
        <v-menu v-model="filterMenu" bottom offset-y nudge-top="-10" :close-on-content-click="false">
            <template v-slot:activator="{ on }">
                <v-chip label small color="primary" class="mr-1 mb-1" v-on="on"><v-icon small color="yellow">mdi-plus</v-icon></v-chip>
            </template>
            <v-card width="680">
                <v-container>
                    <v-row class="border-bottom">
                        <v-col sm="5">
                            <v-select ref="filterQuestion" label="Question" v-model="filterQuestion" :items="filterFields" item-text="text" item-value="value" autofocus>
                            <template v-slot:item="{ item }">
                                <v-icon class="mr-2">{{ item.inIcon }}</v-icon>{{ item.text }}
                            </template>
                            </v-select>
                        </v-col>
                        <v-col sm="1" class="d-flex flex-column justify-center">
                            <v-menu open-on-hover bottom offset-y nudge-left="38" :disabled="filterQuestion == null">
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" text class="pl-0 pr-0" style="min-width:40px;" :disabled="filterQuestion == null" ><v-icon>{{ searchAt.icon }}</v-icon></v-btn>
                                </template>
                                <v-list dense class="pl-2 pr-2">
                                    <v-list-item link class="ma-0 pa-0" v-for="item in searchAtOptions" :key="item.value" @click="onSearchAt(item)">
                                        <v-icon class="mr-2">{{ item.icon }}</v-icon>{{ item.text }}
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                        <v-col sm="5">
                            <component
                                ref="filterValue"
                                :is="filterValueControl"
                                v-bind="$props"
                                v-model="filterValue"
                                clearable
                                placeholder="Filter value"
                                :list="filterValueOptions"
                                :many="filterValueMany"
                                :inputType="filterSubInput"
                                :hint="searchAtText"
                                :error="filterValueError"
                                @send-value="onFilterAdd"
                                @change="onFilterValueChange"
                                @focus="$event.target.select()"
                                :disabled="filterQuestion == null"></component>
                            <slot></slot>
                        </v-col>
                        <v-col sm="1" class="d-flex flex-column justify-center">
                            <v-btn icon @click="onFilterAdd" :disabled="filterQuestion == null"><v-icon>mdi-plus</v-icon></v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-for="item in filters" :key="item._id" class="border-bottom">
                        <v-col sm="5" class="d-flex flex-column justify-center pt-0 pb-0 cur-point" @click="onReSelect(item)">
                            {{ item.Field }}
                        </v-col>
                        <v-col sm="1" class="d-flex flex-column justify-center pt-0 pb-0 cur-point" @click="onReSelect(item)">
                            <span><v-icon>{{ item.Oper.icon }}</v-icon></span>
                        </v-col>
                        <v-col sm="5" class="d-flex flex-column justify-center pt-0 pb-0 cur-point" @click="onReSelect(item)">
                            <span class="pa-2">{{ oneOrMoreDisplay(item.Value) }}</span>
                        </v-col>
                        <v-col sm="1" class="d-flex flex-column justify-center pt-0 pb-0">
                            <v-btn v-if="item.Removable !== false" icon @click="onFilterRemove(item)"><v-icon>mdi-window-close</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="onCloseFilterWindow()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
        <v-chip v-if="!filters.length" small color="grey lighten-3" class="mr-1 mb-1" label>None</v-chip>
        <v-chip v-for="item in filters" :key="item._id" small color="info" class="mr-1 mb-1" label :close="item.Removable !== false" @click:close="onFilterRemove(item)">
            {{ item.Field }} <v-icon small class="ml-1" color="yellow">{{ item.Oper.icon }}</v-icon><strong class="ml-1">{{ oneOrMoreDisplay(item.Value) }}</strong>
        </v-chip>
    </div>
</template>

<script>
import Constants from '@/util/Constants';
import Data from '@/util/Data';
import filterBoolean from '@/controls/filter/Boolean';
import filterDate from '@/controls/filter/Date';
import filterEmail from '@/controls/filter/Email';
import filterList from '@/controls/filter/List';
import filterMobile from '@/controls/filter/Mobile';
import filterNumber from '@/controls/filter/Number';
import filterSelect from '@/controls/filter/Select';
import filterText from '@/controls/filter/Text';
import filterTime from '@/controls/filter/Time';
import filterWeb from '@/controls/filter/Web';
import Util from '@/util/Util';

export default {
    name: 'v-filter',
    components: {
        'v-filter-boolean': filterBoolean,
        'v-filter-date': filterDate,
        'v-filter-email': filterEmail,
        'v-filter-list': filterList,
        'v-filter-mobile': filterMobile,
        'v-filter-number': filterNumber,
        'v-filter-select': filterSelect,
        'v-filter-text': filterText,
        'v-filter-time': filterTime,
        'v-filter-web': filterWeb,
    },
    props: {
        // value: null,
        // width: null,
        surveyFilterFields: Array,
        filterList: Array,
    },
    data () {
        return {
            filterMenu: false,
            filterFields: this.surveyFilterFields || [],
            filterQuestion: null,
            filterValueControl: 'v-text-field',
            filterValue: null,
            filterValueType: 'String',
            filterValueInput: Constants.INPUT_TYPE.Text,
            filterSubInput: Constants.INPUT_TYPE.Text,
            filterValueError: false,
            filterValueOptions: [],
            filterValueMany: false,
            filters: this.filterList || [],
            searchAt: {},
            searchAtText: '',
            searchAtOptions: Constants.SEARCH_STRING,
        };
    },
    methods: {
        setFirstQuestion () {
            if (!this.filterFields.length) return;
            // this.filterQuestion = this.filterFields[0].value;
            for (const o of this.filterFields) {
                switch (o.type) {
                    case 'String': o.inIcon = 'mdi-card-text-outline'; break;
                    case 'Array': o.inIcon = 'mdi-format-list-checkbox'; break;
                    case 'Number': o.inIcon = 'mdi-alpha-n-box-outline'; break;
                    case 'Date': o.inIcon = 'mdi-calendar'; break;
                    case 'DateTime': o.inIcon = 'mdi-calendar'; break;
                    case 'Time': o.inIcon = 'mdi-clock-outline'; break;
                    case 'Boolean': o.inIcon = 'mdi-toggle-switch-off-outline'; break;
                    case 'Dummy': o.inIcon = 'mdi-car-break-alert'; break;
                    case 'Image': o.inIcon = 'mdi-image-outline'; break;
                    case 'Location': o.inIcon = 'mdi-map-marker-outline'; break;
                    default: o.inIcon = 'mdi-card-text-outline'; break;
                }
            }
        },
        onSearchAt (item) {
            this.searchAt = item;
            this.searchAtText = this.searchAt.text;

            this.adjustFilterValueInput();

            this.$refs.filterValue.focus();
        },
        oneOrMoreDisplay (value) {
            return Array.isArray(value) ? Util.oneAndMore(value) : value;
        },
        adjustFilterValueInput () {
            this.filterValue = '';
            this.filterValueMany = false;

            switch (this.filterValueInput) {
                /* case Constants.INPUT_TYPE.Note:
                case Constants.INPUT_TYPE.Text:
                case Constants.INPUT_TYPE.Barcode:
                    this.filterValueControl = 'v-filter-text';
                    this.filterValue = '';
                    break; */
                case Constants.INPUT_TYPE.Number:
                case Constants.INPUT_TYPE.Rating:
                case Constants.INPUT_TYPE.RangeSlider:
                    this.filterValueControl = 'v-filter-number';
                    break;
                case Constants.INPUT_TYPE.Mobile:
                    this.filterValueControl = 'v-filter-mobile';
                    break;
                case Constants.INPUT_TYPE.Email:
                    this.filterValueControl = 'v-filter-email';
                    break;
                case Constants.INPUT_TYPE.WebAddress:
                    this.filterValueControl = 'v-filter-web';
                    break;
                case Constants.INPUT_TYPE.SelectOne:
                case Constants.INPUT_TYPE.SelectMany:
                    this.filterValueControl = 'v-filter-select';
                    this.filterValue = '';
                    // if (this.filterValueInput === Constants.INPUT_TYPE.SelectMany) this.filterValueMany = true;
                    this.filterValueMany = true;
                    // this.searchAt = this.searchAtOptions.find(o => o.text === 'Exact');
                    // this.searchAtText = this.searchAt.text;
                    break;
                case Constants.INPUT_TYPE.YesNo:
                    this.filterValueControl = 'v-filter-boolean';
                    this.filterValue = false;
                    break;
                // case Constants.INPUT_TYPE.GPSLocation: // TODO: Coords + find in radius.
                //     this.filterValueControl = 'v-filter-geo';
                //     this.filterValue = '';
                //     break;
                case Constants.INPUT_TYPE.Date:
                case Constants.INPUT_TYPE.DateOfBirth:
                    this.filterValueControl = 'v-filter-date';
                    this.filterValue = new Date().toISOString().substring(0, 10);
                    break;
                case Constants.INPUT_TYPE.Time:
                    this.filterValueControl = 'v-filter-time';
                    this.filterValue = new Date().toISOString().substring(11, 19);
                    break;
                case Constants.INPUT_TYPE.User:
                    this.filterValueControl = 'v-filter-text';
                    this.filterValue = '';
                    break;
                default: // Default is plain text input.
                    this.filterValueControl = 'v-filter-text';
                    break;
            }

            /* switch (this.filterValueType) {
                case 'String':
                    this.filterValueControl = 'v-filter-text';
                    this.filterValue = '';
                    break;
                case 'Array':
                    this.filterValueControl = 'v-filter-list';
                    this.filterValue = [];
                    break;
                case 'Number':
                    this.filterValueControl = 'v-filter-number';
                    this.filterValue = '';
                    break;
                case 'Date':
                case 'DateTime':
                    this.filterValueControl = 'v-filter-date';
                    this.filterValue = new Date().toISOString().substring(0, 10);
                    break;
                case 'Time':
                    this.filterValueControl = 'v-filter-time';
                    this.filterValue = new Date().toISOString().substring(11, 19);
                    break;
                case 'Boolean':
                    this.filterValueControl = 'v-filter-boolean';
                    this.filterValue = false;
                    break;
                case 'Dummy':
                    this.filterValueControl = 'v-text-field';
                    this.filterValue = '';
                    break;
                case 'Image':
                case 'Location':
                default:
                    this.filterValueControl = 'v-filter-text';
                    break;
            } */
            if (this.searchAt.value === '[]') {
                this.filterValueControl = 'v-filter-list';
                this.filterValue = [];
                setTimeout(() => {
                    this.filterSubInput = this.filterValueInput;
                }, 100);
            }
            /* else if (this.searchAt.value === '&') {
                this.filterValueControl = 'v-filter-between';
                this.filterValue = [];
            } */
        },
        setSearchOptionsByType () {
            const dType = Data.inputDataType(this.filterValueInput, Constants.INPUT_TYPE);
            switch (dType) {
                case 'String':
                case 'Array':
                    this.searchAtOptions = Constants.SEARCH_STRING;
                    break;
                case 'Number':
                case 'Date':
                case 'DateTime':
                case 'Time':
                    this.searchAtOptions = Constants.MATCH_MATH;
                    break;
                case 'Boolean':
                    this.searchAtOptions = Constants.MATCH_BOOL;
                    break;
                case 'Image':
                case 'Location':
                default:
                    this.searchAtOptions = Constants.SEARCH_STRING;
                    break;
            }
        },
        isFilterValueValid () {
            if (this.filterQuestion === null || this.filterValue === null) {
                this.filterValueError = true;
                return false;
            }
            /* switch (this.filterValueInput) {
                case INPUT_TYPE.Note:
                case INPUT_TYPE.Text:
                case INPUT_TYPE.Mobile:
                case INPUT_TYPE.Email:
                case INPUT_TYPE.WebAddress:
                case INPUT_TYPE.SelectOne:
                case INPUT_TYPE.Barcode:
                case INPUT_TYPE.Image:
                case INPUT_TYPE.Signature:
                    return q.ValueStr === undefined ? null : q.ValueStr;
                case INPUT_TYPE.Number:
                case INPUT_TYPE.Rating:
                case INPUT_TYPE.RangeSlider:
                    return q.ValueNum === undefined ? null : q.ValueNum;
                case INPUT_TYPE.YesNo:
                    return q.ValueBool === undefined ? null : q.ValueBool;
                case INPUT_TYPE.SelectMany:
                case INPUT_TYPE.GPSLocation:
                    return q.ValueArr === undefined ? null : q.ValueArr.slice(); // Make a copy.
                case INPUT_TYPE.Date:
                case INPUT_TYPE.DateOfBirth:
                    return q.ValueDate === undefined ? null : q.ValueDate;
                case INPUT_TYPE.Time:
                    return q.ValueTime === undefined ? null : q.ValueTime;
                default:
                    return q.ValueStr === undefined ? null : q.ValueStr;
            } */
            switch (this.filterValueType) {
                case 'String':
                    if (this.filterValue === '') this.filterValueError = true;
                    break;
                case 'Array':
                    if (this.filterValue.length) this.filterValueError = true;
                    break;
                case 'Number':
                    if (this.filterValue === 0) this.filterValueError = true;
                    break;
                case 'Date':
                case 'DateTime':
                    if (this.filterValue === '') this.filterValueError = true;
                    break;
                case 'Time':
                    if (this.filterValue === '') this.filterValueError = true;
                    break;
                case 'Boolean':
                    if (this.filterValue === '') this.filterValueError = true;
                    break;
                case 'Image':
                case 'Location':
                default:
                    if (this.filterValue === '') this.filterValueError = true;
                    break;
            }
            return !this.filterValueError;
        },
        onFilterValueChange (e) {
            this.filterValue = e;
            this.filterValueError = false;
        },
        onFilterAdd (value) {
            if (!(typeof value === 'object' && value.target && value.altKey !== undefined)) {
                this.filterValue = value;
            }
            if (!this.isFilterValueValid()) return;
            this.filterValueError = false;

            // Check if the filed has already been added. If so, replace the value with the new one.
            const exists = this.filters.find(o => o.Field === this.filterQuestion);
            if (exists) {
                exists.Value = this.filterValue;
                exists.Oper = Data.duplicate(this.searchAt);
            }
            else {
                this.filters.push({ _id: Date.now(), Field: this.filterQuestion, Value: this.filterValue, Oper: Data.duplicate(this.searchAt), IType: this.filterValueInput });
            }

            // Reset.
            this.filterQuestion = null;
            this.filterValue = null;
            // Force a change otherwise the input doesn't refresh.
            this.filterValueType = Constants.INPUT_TYPE.User;
            this.adjustFilterValueInput();
            setTimeout(() => {
                this.$refs.filterQuestion.focus();
            }, 50);
            this.$emit('added');
        },
        onFilterRemove (item) {
            const filter = this.filters.find(o => o._id === item._id);
            const pos = this.filters.indexOf(filter);
            this.filters.splice(pos, 1);
            this.$emit('removed');
        },
        onCloseFilterWindow () {
            this.filterQuestion = null;
            this.filterMenu = false;
        },
        onReSelect (item) {
            const value = item.Value;
            this.filterValueError = false;
            this.filterQuestion = item.Field;
            // this.searchAt = this.searchAtOptions.find(o => o.value === item.Oper.value);
            // this.searchAtText = this.searchAt.text;
            this.filterValueControl = '';
            this.filterValueInput = item.IType;
            this.filterValue = null;
            this.setSearchOptionsByType();
            this.adjustFilterValueInput();
            this.$refs.filterValue.focus();
            // Update the icon and value after a bit of a delay.
            setTimeout(() => {
                this.searchAt = this.searchAtOptions.find(o => o.value === item.Oper.value);
                this.searchAtText = this.searchAt.text;
                this.filterValue = value;
            }, 100);
        }
    },
    watch: {
        surveyFilterFields () {
            this.searchAt = this.searchAtOptions.find(o => o.value === 'any');
            this.searchAtText = this.searchAt.text;

            this.filterFields = this.surveyFilterFields || [];
            this.setFirstQuestion();

            this.filterValueInput = Constants.INPUT_TYPE.User;
            this.adjustFilterValueInput();
        },
        filterQuestion () {
            const field = this.filterQuestion === null ? null : this.filterFields.find(o => o.value === this.filterQuestion);
            this.filterValueType = field ? field.type : 'Dummy';
            this.filterValueInput = field ? field.itype : Constants.INPUT_TYPE.Text;
            this.filterValueOptions = field ? field.options || [] : [];

            // Set the match options for the type.
            this.setSearchOptionsByType();
            // Set the default.
            this.searchAt = this.searchAtOptions[0];
            this.searchAtText = this.searchAt.text;

            this.adjustFilterValueInput();

            if (this.$refs.filterValue && this.$refs.filterValue.focus) {
                this.$refs.filterValue.focus();
            }
        },
        filterMenu () {
            // Reload the data when the menu closes and reset the inputs.
            if (!this.filterMenu) {
                this.filterValueInput = Constants.INPUT_TYPE.User;
                this.adjustFilterValueInput();
                this.$emit('closed');
            }
        },
        filters () {
            this.filters = this.filterList || [];
        }
    },
};
</script>

<style  lang="scss" scoped>
.border-bottom {
    border-bottom: 1px solid #CCC;
}
</style>
