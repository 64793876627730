<template>
    <div>
        <v-btn v-if="docUri" :href="docUri" :download="doc.filename" target="_blank">
            {{ doc.filename }}
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'LazyUpload',
    props: {
        input: {
            type: [Object, String],
            default: null
        }
    },
    data () {
        return {
            doc: null,
            docUri: null
        };
    },
    mounted () {
        this.doc = this.input || null;
        this.fetchDocument();
    },
    methods: {
        fetchDocument () {
            this.docUri = null;
            if (!this.doc || !this.doc.Value) {
                return;
            }

            this.$http.request({ url: this.doc.Value, method: 'GET', responseType: 'blob' })
                .then(res => {
                    if (res && res.data) {
                        this.docUri = URL.createObjectURL(res.data);
                    }
                });
        }
    },
    watch: {
        input: {
            handler () {
                this.doc = this.input || null;
                this.fetchDocument();
            }
        }
    }
};
</script>
