<template>
    <v-container>
      <!-- Render barcode input fields dynamically based on the lines prop -->
      <div v-for="(control, index) in controls" :key="index">
        <v-text-field
          ref="barcodeInput"
          :label="control.isFocused ? 'Waiting for scanner...' : 'Scanned Barcode #' + (index + 1)"
          v-model="control.model"
          readonly
          :class="{ 'focused': control.isFocused }"
          @focus="onFocus(index)"
          @blur="onBlur(index)"
          @keydown="captureBarcode($event, index)"
          v-bind="$attrs"
          prepend-icon="mdi-barcode"
        />
      </div>

      <!-- Show + button to add a new barcode input (only if manual is true) -->
      <v-btn @click="addBarcode" color="primary" icon v-if="manual">
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <!-- Show - button to remove the last barcode input (only if manual is true and more than one input exists) -->
      <v-btn @click="removeBarcode" color="error" icon v-if="manual && controls.length > 1">
        <v-icon>mdi-minus</v-icon>
      </v-btn>
    </v-container>
  </template>

<script>
export default {
    name: 'v-barcode-scanner',
    props: {
        value: {
            type: [Array, String], // Can be an array or string
            default: () => [] // Default to an empty array
        },
        lines: {
            type: Number,
            default: 1 // Default to 1 input field
        },
        manual: {
            type: Boolean,
            default: false // Default is false, no + or - buttons
        }
    },
    emits: ['input', 'enter'], // Emit when barcode is captured
    data () {
        // Convert the value prop to an array if it's not already
        const initialValues = Array.isArray(this.value) ? this.value : [this.value || ''];

        return {
            controls: Array.from({ length: this.lines }, (_, index) => ({
                model: initialValues[index] || '',
                isFocused: false
            })),
            tempBarcodes: Array(this.lines).fill(''),
            scanTimeout: null,
            inputTimeouts: [] // To handle input buffering timeouts per input field
        };
    },
    methods: {
        clear () {
            // Reset the controls and tempBarcodes to their initial empty states
            this.controls = Array.from({ length: this.lines }, () => ({
                model: '',
                isFocused: false
            }));
            this.tempBarcodes = Array(this.lines).fill(''); // Reset tempBarcodes
            this.$emit('clear'); // Emit a 'clear' event to notify parent
        },
        captureBarcode (event, index) {
            if (event.key.length > 1 && event.key !== 'Enter') {
                return;
            }

            const control = this.controls[index];

            // On pressing Enter, save the barcode and emit it to the parent
            if (event.key === 'Enter') {
                const scannedValue = this.tempBarcodes[index];

                // Parse the value to a number before assigning it to the model
                const parsedValue = isNaN(scannedValue) ? scannedValue : parseFloat(scannedValue);

                // Set the model to the parsed value (either number or string)
                control.model = parsedValue;

                this.$emit('input', this.controls.map(c => c.model)); // Emit all scanned barcodes
                this.$emit('enter', this.controls.map(c => c.model)); // Notify that scanning is complete
                this.tempBarcodes[index] = ''; // Clear buffer for this input

                // Focus the next input field after scanning
                this.focusNext(index);
            }
            else {
                // Append character from barcode scanner
                this.tempBarcodes[index] += event.key;
            }

            // Reset the buffer for this barcode after 100ms of no activity (scanner delay)
            clearTimeout(this.inputTimeouts[index]);
            this.inputTimeouts[index] = setTimeout(() => {
                this.tempBarcodes[index] = '';
            }, 100);
        },

        addBarcode () {
            if (this.manual) {
                // Add a new barcode input only if manual is true
                this.controls.push({ model: '', isFocused: false });
                this.tempBarcodes.push(''); // Add an empty buffer for the new input

                // Focus the newly added input field
                this.$nextTick(() => {
                    this.controls[this.controls.length - 1].isFocused = true;
                    this.$refs.barcodeInput[this.controls.length - 1].focus(); // Focus the last added field
                });
            }
        },

        removeBarcode () {
            if (this.manual && this.controls.length > 1) {
                // Remove the last barcode input only if manual is true and more than one input exists
                this.controls.pop();
                this.tempBarcodes.pop(); // Remove the corresponding tempBarcode
            }
        },

        onFocus (index) {
            this.controls[index].isFocused = true;
            this.controls[index].model = ''; // Clear model to prepare for new barcode input
        },

        onBlur (index) {
            this.controls[index].isFocused = false;
        },

        // Focus the next input field in the list
        focusNext (index) {
            if (index < this.controls.length - 1) {
                this.controls[index + 1].isFocused = true;
                this.$nextTick(() => {
                    this.$refs.barcodeInput[index + 1].focus(); // Focus the next barcode input
                });
            }
        }
    },
    watch: {
        value (newVal) {
        // Sync the external value with the controls when it changes
            const initialValues = Array.isArray(newVal) ? newVal : [newVal || ''];

            if (newVal && newVal.length !== this.controls.length) {
                this.controls = initialValues.map(barcode => ({ model: barcode, isFocused: false }));
                this.tempBarcodes = new Array(initialValues.length).fill(''); // Reset tempBarcodes
            }
        },
        lines (newVal) {
        // Adjust the number of inputs when the lines prop changes
            this.controls = Array.from({ length: newVal }, (_, index) => ({
                model: this.controls[index]?.model || '', // Preserve existing values
                isFocused: false
            }));
            this.tempBarcodes = new Array(newVal).fill(''); // Reset tempBarcodes when lines prop changes
        }
    }
};
</script>

  <style scoped>
  /* Custom focus style for the barcode input fields */
  .v-text-field.focused {
    border-color: #1976d2; /* Example color for focus state */
  }
  </style>
